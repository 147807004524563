<template>
  <div class="main" style="min-height: calc(100vh - 70px)">
   <Header/>
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <div class="mask" v-if="maskShow">
        <div class="register" v-if="registerShow" key="register">
          <i class="el-icon-close" @click="closeMask('registerForm')"></i>
          <span>注册</span>
          <form>
            <label class="label-name-icon">
              <input
                type="text"
                placeholder="真实姓名"
                @blur="checkName('name-check')"
                v-model="resgisterForm.name"
              />
              <b class="check-mark" ref="name-check">请输入姓名</b>
            </label>
            <label class="label-unit-icon">
              <el-autocomplete
                placeholder="单位"
                :trigger-on-focus="false"
                :fetch-suggestions="querySearch"
                value-key="name"
                v-model="unitName"
                @select="assignQueryString"
                @blur="checkCompany('company-check', 'company-right-check')"
              ></el-autocomplete>
              <b class="check-mark" ref="company-check">请输入单位名称</b>
              <b class="check-mark" ref="company-right-check"
                >请输入正确的公司名称</b
              >
            </label>
            <label class="label-unit-icon">
               <input
                type="text"
                placeholder="请务必输入企业全称"
                @blur="checkCompany('company-check', 'company-right-check')"
                v-model="resgisterForm.company"
              ></input>
              <b class="check-mark" ref="company-check">请输入企业名称</b>
              <b class="check-mark" ref="company-right-check"
                >请输入企业全称</b
              >
            </label>
            <label class="label-phone-icon">
              <input
                type="number"
                placeholder="手机号"
                @blur="checkMobile('mobile-check')"
                @input="
                  if (resgisterForm.mobile.length > 11)
                    resgisterForm.mobile = resgisterForm.mobile.slice(0, 11);
                "
                v-model="resgisterForm.mobile"
              />
              <b class="check-mark" ref="mobile-check">请输入手机号</b>
            </label>
            <label class="label-code-icon">
              <input
                type="number"
                placeholder="验证码"
                @blur="checkCaptcha('captcha-check')"
                v-model="resgisterForm.code"
              />
              <button
                class="send-code-btn"
                @click.prevent="sendCode"
                v-show="countDown === 60"
              >
                发送验证码
              </button>
              <button
                class="send-code-btn send-disable"
                disabled
                v-show="countDown !== 60"
              >
                重新发送( {{ countDown }} )
              </button>
              <b class="check-mark" ref="captcha-check">请输入验证码 </b>
            </label>
            <label class="label-password-icon">
              <input
                type="password"
                placeholder="密码"
                maxlength="16"
                v-model="resgisterForm.password"
                @blur="checkPwd('password-check')"
                @input="
                  resgisterForm.password = resgisterForm.password.replace(
                    /[^\w\.\/]/gi,
                    ''
                  )
                "
              />
              <b class="check-mark" ref="password-check">请输入密码</b>
            </label>
            <label class="label-password-icon">
              <input
                type="password"
                maxlength="16"
                placeholder="确认密码"
                v-model="resgisterForm.real_password"
                @input="
                  resgisterForm.real_password =
                    resgisterForm.real_password.replace(/[^\w\.\/]/gi, '')
                "
                @blur="checkRePwd('re-password-check')"
              />
              <b class="check-mark" ref="re-password-check">请输入确认密码</b>
            </label>
            <button class="now-register" @click.prevent="registerReq">
              立即注册
            </button>
          </form>
        </div>
        <div class="login-mask" v-if="loginShow" key="login">
          <i class="el-icon-close" @click="closeLoginMask('loginForm')"></i>
          <span>登录</span>
          <el-form
            ref="loginForm"
            label-width="auto"
            :rules="loginRule"
            :model="loginForm"
          >
            <el-form-item prop="mobile">
              <p slot="label" style="margin: 0"></p>
              <el-input
                placeholder="请输入手机号"
                prefix-icon="el-icon-mobile-phone"
                v-model="loginForm.mobile"
                type="number"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <p slot="label" style="margin: 0"></p>
              <el-input
                type="password"
                placeholder="请输入密码"
                prefix-icon="el-icon-lock"
                maxlength="16"
                @input="
                  loginForm.password = loginForm.password.replace(
                    /[^\w\.\/]/gi,
                    ''
                  )
                "
                v-model="loginForm.password"
                :onkeyup="(loginForm.password = loginForm.password.trim())"
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                @click.prevent="loginMethods('loginForm')"
                >登录</el-button
              >
              <el-button @click="closeLoginMask('loginForm')">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="forget-mask" v-if="forgetShow" key="forget">
          <i class="el-icon-close" @click="closeForget('forgetForm')"></i>
          <span>忘记密码</span>
          <el-form
            :rules="forgetRule"
            :model="forgetForm"
            ref="forgetForm"
            label-width="auto"
          >
            <el-form-item prop="mobile">
              <p slot="label" style="margin: 0"></p>
              <el-input
                type="number"
                placeholder="请输入手机号"
                prefix-icon="el-icon-mobile-phone"
                v-model="forgetForm.mobile"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="code">
              <p slot="label" style="margin: 0"></p>
              <div class="msg-box">
                <el-input
                  type="number"
                  placeholder="请输入短信验证码"
                  prefix-icon="el-icon-lock"
                  v-model="forgetForm.code"
                >
                </el-input>
                <button
                  @click.prevent="sendForgetCode"
                  v-show="countDown === 60"
                >
                  获取验证码
                </button>
                <button v-show="countDown !== 60">
                  重新发送 ({{ countDown }})
                </button>
              </div>
            </el-form-item>
            <el-form-item prop="password">
              <p slot="label" style="margin: 0"></p>
              <el-input
                maxlength="16"
                type="password"
                placeholder="请输入新密码"
                prefix-icon="el-icon-lock"
                v-model="forgetForm.password"
                @input="
                  forgetForm.password = forgetForm.password.replace(
                    /[^\w\.\/]/gi,
                    ''
                  )
                "
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="real_password">
              <p slot="label" style="margin: 0"></p>
              <el-input
                maxlength="16"
                type="password"
                placeholder="请确认密码"
                prefix-icon="el-icon-lock"
                v-model="forgetForm.real_password"
                @input="
                  forgetForm.real_password = forgetForm.real_password.replace(
                    /[^\w\.\/]/gi,
                    ''
                  )
                "
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="forgetReq('forgetForm')"
                >确定</el-button
              >
              <el-button @click="closeForget('forgetForm')">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </transition>
    <div class="carousel-login">
      <swiper ref="mySwiper" :options="swiperOptions" class="swiper">
        <swiper-slide
          v-for="banner in bannerList"
          :key="banner.id"
          :style="{
            backgroundImage:
              'url(' + banner.pic + ')',
          }"
        >
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <!-- DONE:根据登录判断显示 -->
      <div class="login-window">
        <div class="logined" v-if="userInfo">
          <div class="logined-img" @click="$router.push('/personal')">
            <img
              v-if="userInfo.head_img && userInfo.head_img !== ''"
              :src="userInfo.head_img"
              alt=""
            />
            <img v-else src="@/assets/images/default_avator.png" alt="" />
          </div>
          <span class="logined-name">{{ userInfo.name }}</span>
          <span class="logined-company">{{ userInfo.company_name }}</span>
          <button @click="$router.push('/personal')">进入个人中心</button>
        </div>
        <div class="no-login" v-if="!userInfo">
          <div class="logined-img">
            <img src="@/assets/images/default_avator.png" alt="" />
          </div>
          <p>点击下方登录开始学习吧！</p>
          <button @click="loginMask">登录</button>
          <p>
            <span>
              <!-- <el-link :underline="false">还没账号?</el-link> -->
              &ensp;
              <el-link
                :underline="false"
                type="primary"
                @click="openMask"
                v-if="registerStatus"
              >
                立即注册&ensp;
              </el-link>
              <el-link :underline="false" type="info" @click="openForget">
                忘记密码
              </el-link>
            </span>
          </p>
        </div>
      </div>
    </div>
    <div class="category-recommend">
      <div class="category-course">
        <span class="course-title">课程分类</span>
        <el-link :underline="false" @click="$router.push('/courtype')"
          >更多</el-link
        >
        <div class="category-box">
          <!-- <div
            v-for="type in courseType"
            :key="type.id"
            class="course-item"
            :style="{
              background:
                'url(' + type.pic + ') no-repeat',
            }"
            @click="
              $router.push(`/courlist?tid=${type.id}&tn=${type.type_name}`)
            "
          > -->
          <div
            v-for="type in courseType"
            :key="type.id"
            class="course-item"
            :style="{
              background:
                'url(' + type.pic + ') no-repeat',
            }"
            @click="typeShow(type)"
          >
            <img :src="type.pic" />
            <!-- 图片上的名字 -->
            <!-- <b>{{ type.type_name }}</b> -->
          </div>
        </div>
      </div>
      <!-- <div class="recommend-course">
        <span class="course-title">推荐课程</span>
        <el-link :underline="false" @click="$router.push('/courlist?type=rc')"
          >更多</el-link
        >
        <div class="recommend-box">
          <div
            class="recommend-item"
            @click="
              $router.push(
                `/courdetails?cid=${recommend.id}&ct=${recommend.title}`
              )
            "
            v-for="recommend in courseRecommend"
            :key="recommend.id"
          >
            <div class="recommend-item-img">
              <img
                :src="recommend.pic"
                :alt="recommend.title"
              />
            </div>
            <p class="recommend-item-text">
              <span class="text-title">{{ recommend.title }}</span>
              <span class="text-time">{{ recommend.video_time }}课时</span>
            </p>
          </div>
        </div>
      </div> -->
    </div>
    <div class="allcontent">
      <div class="content-text">
        <span class="text-pingshen">各省职称通知</span>
        <span class="text-pdf">标准规范</span>
        <div class="box">
          <div class="pingshen-box">
            <el-card class="box-cards" shadow="never">
              <div class="clearfix text" >
                <span class="clearfix-item" :class="isactive === 9999 ? 'addclass' : '' " @click="reviewData(0,9999)" style="padding-right: 17px">推荐</span>
                <span :class="isactive === res.id ? 'addclass' : '' " class="clearfix-item" @click="reviewData(res.id)" style="padding-right: 15px" v-for="res in this.areaInfo" :key="res.id" >{{res.name}}</span>
              </div>
            </el-card>
            <el-card class="box-cardxs" shadow="never">
              <div v-for="res in this.reviewInfo" :key="res.id" class="text item" style="display: flex;">
                <div v-if="res.c_type == 1">
                  <span><img src="../assets/images/ping.png"></span>
                </div>
                <div v-else>
                  <span><img src="../assets/images/kao.png"></span>
                </div>
                <div :title="res.title" style="width: 600px" class="box-centens">
                  <span @click="NewsContent(res.id)"  style="padding-left: 10px;" > {{res.title}} </span>
                </div>
                <div>
                  <span style="padding-left: 55px">{{res.create_time}}</span>
                </div>
              </div>
            </el-card>
          </div>
          <div class="pdf-box">
              <el-card class="box-card" shadow="never">
                <img src="../assets/images/pdf.png" alt=""/>
                <div @click="viewPdf(res.url)" v-for="res in this.pdfInfo" :key="res.id" class="text item">
                  {{res.title}}
                </div>
              </el-card>
          </div>
        </div>
      </div>
    </div>
    <!--点击课程类型弹框 -->
    <div class="courseListShow" v-if="courseListShow">
       <div class="el-icon-close icon-close" @click="typeShowClose">
        <!-- <img style="width:30px;height:30px"  src="../assets/images/close_btn.png" alt=""> -->
       </div>
       <div class="showTop">
         <p style="font-size:26px;padding:0 45px;">{{frameList.type_name !==null&&frameList.type_name !==" " ? frameList.type_name:"暂未添加名称"}}</p>
         <span style="color:#999;padding:0 45px;display:block;margin-top:-10px;">课程有效期:一年</span>
       </div>
       <div class="showItemBox">
       <!-- 每一个购买模块 -->
        <div class="showItem" v-for="i in courseList">
          <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item :title=i.c_title style="font-size: 28px">
          <div style="display:flex" v-for="o in i.video_list">
              <span style="display:inline-block;margin-bottom:8px;margin-left: 20px">{{o.number+"、"}}</span><span style="display:inline-block;margin-top:0px;margin-bottom:-4px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;width:400px;">{{o.v_title}}</span>
          </div>
          </el-collapse-item>
          </el-collapse>
       </div>

      </div>

       <!-- 下边购买横条 -->
       <div class="showBottom">
         <span class="bottomLeft" style="color:red;font-weight:500;" >{{frameList.price}}元</span>
         <el-button class="bottomRight" type="primary" @click="buy">购买</el-button>
       </div>
    </div>
    <!-- 购买弹窗 -->
    <div class="mask-code" v-show="codeShow">
      <div class="code-box">
        <!-- <close-circle-outlined class="wechat-close" @click="closeMask" /> -->
        <i class="el-icon-circle-close icon" @click="closeMaskzhifu"></i>
        <div class="code-img" id="qrcode"></div>
        <p>使用微信扫描二维码进行支付</p>
      </div>
    </div>
  <Foot/>
    <!-- <div class="tech-support">技术支持：北京小兰老师科技有限公司</div> -->

  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Foot from "../components/Foot.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import "animate.css";
import QRCode from "qrcodejs2";
import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import index from "vuex";
Vue.use(ElementUI);
export default {
  data() {
    return {
      isactive:false,
      courseListShow:false,//点击课程类型弹框默认不显示
      maskShow: false,
      registerShow: false,
      loginShow: false,
      forgetShow: false,
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
        },
        loop: true,
        autoplay: true,
        initialSlide: 1,
      },
      // 表单
      loginForm: {
        mobile: "",
        password: "",
      }, //登陆表单
      loginRule: {
        mobile: { required: true, message: "请输入手机号", trigger: "blur" },
        password: { required: true, message: "请输入密码", trigger: "blur" },
      }, //登陆表单验证
      resgisterForm: {
        name: "",
        //c_id: "",
        company:"",
        mobile: "",
        code: "",
        password: "",
        real_password: "",
      },
      unitName: "", //单位名称
      countDown: 60,
      unitList: [], //查询单位列表
      forgetRule: {
        mobile: { required: true, message: "请输入手机号", trigger: "blur" },
        code: { required: true, message: "请输入验证码", trigger: "blur" },
        password: { required: true, message: "请输入密码", trigger: "blur" },
        real_password: {
          required: true,
          message: "请输入确认密码",
          trigger: "blur",
        },
      },
      forgetForm: {
        mobile: "",
        code: "",
        password: "",
        real_password: "",
      },

      userInfo: null, //用户信息,判断是否显示登陆
      bannerList: [],
      courseType: {}, //课程分类数据,
      courseRecommend: {}, //推荐课程数据
      registerStatus: 0,
      type_id: "",
      frameList:[],//弹框支付课程类型数据
      courseList:[],//弹框支付列表数据
      codeShow:false,//支付弹窗默认不显示
      qrcode:"",//
      interval:null,//判断支付的定时器
      pdfInfo:[],
      areaInfo:[],
      reviewInfo:[],
    };
  },

  components: { Swiper, SwiperSlide, Header,Foot},

  computed: {
    index() {
      return index
    }
  },
  mounted() {
    this.getIndexData();
    this.pdfData();
    this.areaData();
    this.reviewData(0,9999);

    // alert(this.pdfInfo);
    // new QRCode(document.getElementById("qrcode"), "http://jindo.dev.naver.com/collie")

  },
  methods: {
    // 购买支付
    buy(){
      let fd = new FormData();
      fd.append("type_id", this.type_id);
      fd.append("price", this.frameList.price);
         this.axios.post("/index/pay",fd).then((res) => {
        console.log(res);
       this.createQRcode(res.data.data);//执行函数让二维码生成链接
       this.listenOrder(res.data.out_trade_no);//监听它是否已经支付
        this.codeShow=true;
        });
    },
    // 用上边返的链接生成二维码
 createQRcode (url) {
  //  let interval, erweima;
  this.qrcode=document.getElementById("qrcode");
  var erweima = new QRCode(document.getElementById("qrcode"), {
    text: url, // 需要转换为二维码的内容
    width: 250,
    height: 250,
    colorDark: "#000",
    colorLight: "#fff",
    correctLevel: QRCode.CorrectLevel.H,
  }); // 设置要生成二维码的链接
},
// 监听是否支付
 listenOrder (noid) {
  this.selectOrder(noid);
},
 selectOrder (noid){
  this.interval = setInterval(async () => {
    let fd = new FormData();
    fd.append("number", noid);
    this.axios.post("/index/selectorder", fd).then((res)=>{
    if (res.data.trade_state === "SUCCESS") {
      clearInterval(this.interval);
      this.closeMaskzhifu();//二维码清空
      this.courseListShow=false;//支付弹窗清空
      this.maskShow=false;//遮罩隐藏
      // this.$message.destroy();
      this.$message.success("支付成功!");
      // let e = {
      //   dataset: {
      //     type: "show",
      //   },
      // };
      // emit("navChange", e);
      // state.showList();
    }
    })

  }, 2000);
},
    // 关闭支付
   closeMaskzhifu (){
      this.codeShow=false;
      this.qrcode.innerHTML = "";
     clearInterval(this.interval);
},

    // 首页数据
    getIndexData() {
      this.axios.get("/index").then((res) => {
        this.userInfo = res.data.user_info;
        this.courseType = res.data.course_type.splice(0, 5);
        this.courseRecommend = res.data.recommend_course;
        this.registerStatus = res.data.business.is_start;
        document.title = res.data.business.name;
        res.data.banner_list.forEach((ele) => {
          if (ele.is_mobile == 0) {
            this.bannerList.push(ele);
          }
        });
        if (
          res.data.user_info &&
          typeof this.$store.state.empInfo !== "Object"
        ) {
          this.$store.dispatch(
            "SET_EMPLOYEE_INFO",
            JSON.stringify(res.data.user_info)
          );
        }
        if (JSON.stringify(this.$store.state.business) === "{}") {
          this.$store.dispatch(
            "SET_BUSINESS_INFO",
            JSON.stringify(res.data.business)
          );
        }
      });
    },
    pdfData(){
      this.axios.get("/index/standard_pdf").then((res) => {
        this.pdfInfo = res.data.list;
      });
    },
    areaData(){
      this.axios.get("/home/province_list").then((res) => {
        this.areaInfo = res.data;
      });
    },
    reviewData(p_id,all){
      if(all === 9999){
        this.isactive = 9999
      }else{
        this.isactive = p_id
      }
      this.axios.get("/index/review_list?p_id="+p_id).then((res) => {
        this.reviewInfo = res.data.list;
      });
    },
    // 跳到评审详情页面
    NewsContent(id) {
      this.$router.push(`/reviewNewsContent?id=${id}`);
    },
    viewPdf(url) {
      this.$router.push(`/pdfviewer?pu=${url}`);
    },
    //点击课程类型弹框
     typeShow(type){
      //  请求一下courselist那个接口，从里边code判断
      //  如果它购买了此课程，就直接点进去，否则就弹框让他购买
      console.log(type);
      this.type_id=type.id;
      let fd = new FormData();
      fd.append("type_id", type.id);
      fd.append("page_size", 1);
      this.axios.post("/index/course_list", fd).then((res) => {
        console.log(res);
        console.log(res.data.code);
       if(res.data.code==22){//说明用户未登录，弹登录弹窗
            this.maskShow = true;
            this.loginShow = true;
         }else{
        this.courseList=res.data.course_list;
        this.frameList=res.data.type_info;
        console.log(res.data.code);
        if(res.data.code==1){//code==1说明他没购买
            this.maskShow = true;
            this.courseListShow=true;
        }else{//说明他已购买，直接给它展示课程类型
         this.$router.push(`/courlist?tid=${type.id}&tn=${type.type_name}`)
        }
         }
      });
     },
    //  关闭课程类型弹框
    typeShowClose(){
      this.maskShow=false;
      this.courseListShow=false;
      this.type_id="";
    },
    openMask() {
      this.maskShow = true;
      this.registerShow = true;
      // this.loginShow = false;
      this.axios.post("/login/company").then((res) => {
        this.unitList = res.data;
      });
    },
    closeMask() {
      this.maskShow = false;
      this.registerShow = false;
      for (let i in this.resgisterForm) {
        this.resgisterForm[i] = "";
      }
      this.unitName = "";
    },
    // 登陆弹窗
    loginMask() {
      this.maskShow = true;
      this.loginShow = true;
    },
    closeLoginMask(formName) {
      this.maskShow = false;
      this.loginShow = false;
      this.$refs[formName].resetFields();
    },
    // 忘记密码弹窗
    openForget() {
      this.maskShow = true;
      this.forgetShow = true;
    },
    closeForget(formName) {
      console.log(formName);
      this.maskShow = false;
      this.forgetShow = false;
      this.$refs[formName].resetFields();
    },
    // 登陆按钮
    loginMethods(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let fd = new FormData();
          let { mobile, password } = this.loginForm;
          fd.append("mobile", mobile);
          fd.append("password", password);
          this.axios.post("/login/login", fd).then((res) => {
            if (res.data.code === 0) {
              localStorage.setItem("eft", res.data.data.token);
              this.$message.success(res.data.msg);
              this.$router.go(0);
              setTimeout(() => {
                this.maskShow = false;
                this.loginShow = false;
                this.$refs[formName].resetFields(); //清空表单
                this.bannerList = [];
                this.getIndexData();
              }, 800);
            } else {
              this.$message.error(res.data.msg);
            }
          });
        }
      });
    },
    // 注册表单验证
    checkName(b) {
      if (this.resgisterForm.name === "") {
        this.$refs[b].style.opacity = 1;
      } else {
        this.$refs[b].style.opacity = 0;
      }
    },
    checkCompany(b,br) {
      if (this.resgisterForm.company === "") {
        this.$refs[b].style.opacity = 1;
      }
      if (this.resgisterForm.company !== "") {
        this.$refs[b].style.opacity = 0;
      }
     if(this.resgisterForm.company.length>0&&this.resgisterForm.company.length<7){
       this.$refs[br].style.opacity=1;
     }
      if(this.resgisterForm.company.length>=7){
        this.$refs[br].style.opacity=0;
      }
      // console.log(this.resgisterForm.company.length);

    },
    // 下拉建议用的
    // checkCompany(b, br) {
    //   if (this.unitName === "") {
    //     this.$refs[b].style.opacity = 1;
    //     return;
    //   } else {
    //     this.$refs[b].style.opacity = 0;
    //   }
    //   let obj = this.unitList.filter((item) => {
    //     if (item.name === this.unitName) return item;
    //   });
    //   if (obj[0]) {
    //     this.resgisterForm.c_id = obj[0].id;
    //     this.$refs[br].style.opacity = 0;
    //   } else {
    //     this.$refs[br].style.opacity = 1;
    //   }
    // },
    checkMobile(b) {
      if (this.resgisterForm.mobile === "") {
        this.$refs[b].style.opacity = 1;
      } else {
        this.$refs[b].style.opacity = 0;
      }
    },
    checkCaptcha(b) {
      if (this.resgisterForm.captcha === "") {
        this.$refs[b].style.opacity = 1;
      } else {
        this.$refs[b].style.opacity = 0;
      }
    },
    checkPwd(b) {
      if (this.resgisterForm.password === "") {
        this.$refs[b].style.opacity = 1;
      } else {
        this.$refs[b].style.opacity = 0;
      }
    },
    checkRePwd(b) {
      if (this.resgisterForm.real_password === "") {
        this.$refs[b].style.opacity = 1;
      } else {
        this.$refs[b].style.opacity = 0;
      }
    },
    // // 公司单位输入建议
    // querySearch(qs, cb) {
    //   let fd = new FormData();
    //   fd.append("keyword", qs);
    //   this.axios.post("/login/keyword_matching", fd).then((res) => {
    //     if (res.data.code !== 1) {
    //       cb(res.data);
    //     } else {
    //       cb([]);
    //     }
    //   });
    // },
    // assignQueryString(item) {
    //   this.resgisterForm.c_id = item.id;
    //   this.unitName = item.name;
    //   this.checkCompany("company-check", "company-right-check");
    // },

    sendCode() {
      if (this.resgisterForm.mobile === "") {
        this.$message.error("请输入手机号");
        return;
      }
      let fd = new FormData();
      fd.append("mobile", this.resgisterForm.mobile);
      this.axios.post("/login/sendcode", fd).then((res) => {
        if (res.data.code === 0) {
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
      });
      let timer = setInterval(() => {
        this.countDown--;
        if (this.countDown === 0) {
          clearInterval(timer);
          this.countDown = 60;
        }
      }, 1000);
    },
    // 用户注册
    registerReq() {
      let fd = new FormData();
      for (let item in this.resgisterForm) {
        if (this.resgisterForm[item] === "") {
          this.$message.error("请填写完整");
          console.log(this.resgisterForm[item]);

          return;
        } else {
          fd.append(item, this.resgisterForm[item]);
        }
      }
      this.axios.post("/login/register", fd).then((res) => {
        if (res.data.code === 0) {
          this.$message.success(res.data.msg);
          setTimeout(() => {
            this.closeMask();//关闭注册弹框
            this.$store.state.loginMethods();
            // let fd = new FormData();
            // let { mobile, password } = this.resgisterForm;
            // fd.append("mobile", mobile);
            // fd.append("password", password);
            // this.axios.post("/login/login", fd).then((res) => {
            //   if (res.data.code === 0) {
            //     localStorage.setItem("eft", res.data.data.token);
            //     this.$message.success(res.data.msg);
            //     setTimeout(() => {
            //       this.maskShow = false;
            //       this.loginShow = false;
            //       this.$refs[loginForm].resetFields(); //清空表单
            //       this.bannerList = [];
            //       this.getIndexData();
            //     }, 800);
            //   } else {
            //     this.$message.error(res.data.msg);
            //   }
            // });
          }, 800);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 忘记密码
    forgetReq(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let fd = new FormData();
          for (let i in this.forgetForm) {
            fd.append(i, this.forgetForm[i]);
          }
          // DONE
          this.axios.post("/login/forget_pwd", fd).then((res) => {
            if (res.data.code === 0) {
              this.$message.success(res.data.msg);
              setTimeout(() => {
                this.closeForget(formName);
                this.$refs[formName].resetFields();
                this.$store.state.loginMethods();
              }, 800);
            } else {
              this.$message.error(res.data.msg);
            }
          });
        }
      });
    },
    sendForgetCode() {
      if (this.forgetForm.mobile === "") {
        this.$message.error("请输入手机号");
        return;
      }
      let fd = new FormData();
      fd.append("mobile", this.forgetForm.mobile);
      fd.append("sign", 1);
      this.axios.post("/login/sendcode", fd).then((res) => {
        if (res.data.code === 0) {
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
      });
      let timer = setInterval(() => {
        this.countDown--;
        if (this.countDown === 0) {
          clearInterval(timer);
          this.countDown = 60;
        }
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
.addclass{
  color: #409EFF;
}
.main{
  width: 100%;
  min-height: 800px;
  background: #f8f8f8;
  border-radius: 0px 0px 0px 0px;
  // background: #F4F4F4;
  overflow: hidden;
  margin: 0 auto;
  // position:relative;

}
.carousel-login {
  width: 500px;
  position: relative;
  margin: 20px auto;
  .swiper {
    width: 908px;
    height: 318px;
    border-radius: 6px 6px 6px 6px;
    right: 69%;
    .swiper-slide {
      background-position: center;
      background-size: cover;
    }
  }
  .login-window {
    width: 272px;
    height: 318px;
    background: #ffffff;
    border-radius: 6px;
    position: absolute;
    top: 50%;
    left: 114%;
    transform: translateY(-50%);
    text-align: center;
    .no-login,
    .logined {
      .logined-img {
        width: 63px;
        height: 63px;
        border-radius: 50%;
        overflow: hidden;
        margin: 40px auto 24px;
        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      p {
        margin: 0;
        font-size: 14px;
        color: #aca9a9;
      }
      button {
        width: 178px;
        height: 35px;
        background: #8bb9ff;
        opacity: 1;
        border-radius: 22px;
        color: #ffffff;
        font-size: 14px;
        border: 0;
        cursor: pointer;
        margin-top: 54px;
        margin-bottom: 11px;
      }
      span {
        display: flex;
        align-content: center;
        justify-content: center;
        font-size: 14px;
        color: #333333;
      }
      .logined-company {
        margin-top: 11px;
      }
    }
  }
}
.allcontent{
  max-width: 1200px;
  margin: 2px auto 0;
  .box{
    display: flex;
  }
  .text-pingshen {
    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    font-size: 20px;
    color: #333333;
    line-height: 0px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    padding-left: 7px;
  }
  .text-pdf {
    padding-left: 730px;
    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    font-size: 20px;
    color: #333333;
    line-height: 0px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }
  .pingshen-box{
    margin-top: 25px;
    .text {
      font-size: 14px;
    }

    .item {
      margin-bottom: 19px;
    }

    .clearfix{
      line-height: 30px;
    }
    .clearfix-item:hover{
      color:  #409EFF;
      cursor: pointer;
    }
    //.clearfix-item:active{
    //  width: 20px;
    //  height: 20px;
    //  background-color: red;
    //}

    .box-cards {
      width: 825px;
      height: 125px;
    }
    .box-cardxs{
      height: 230px;
      overflow-y: scroll;
      .box-centens{
        white-space: nowrap; /* 不换行 */
        overflow: hidden; /* 隐藏超出的内容 */
        text-overflow: ellipsis; /* 用省略号表示被隐藏的部分 */
        cursor: pointer;
      }
      .box-centens:hover{
        color:  #409EFF;
        cursor: pointer;
      }
    }
    //.box-cardxs::-webkit-scrollbar {
    //  display: none;
    //}
  }
  .pdf-box{
    margin-top: 25px;
    margin-left: 20px;
    .text {
      font-size: 14px;
    }

    .item {
      padding: 9px 0;
      padding-left: 10px;

    }
    .item:hover{
        color:  #409EFF;
        cursor: pointer;
    }
    .box-card {
      width: 345px;
      height: 357px;
    }
    img{
      max-width: 100%;
    }
  }
}
.category-recommend {
  max-width: 1200px;
  margin: 27px auto 0;
  .course-title {
    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    font-size: 20px;
    color: #333333;
    line-height: 0px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    padding-left: 7px;
  }
  .category-course {
    & > a {
      font-family: PingFang SC, PingFang SC;
      font-weight: bold;
      font-size: 20px;
      color: #333333;
      font-style: normal;
      text-transform: none;
      float: right;
      padding-right: 12px;
    }
    .category-box {
      padding: 24px 6px;
      box-sizing: border-box;
      .course-item {
        display: inline-block;
        width: 280px;
        height: 160px;
        margin-right: 22px;
        position: relative;
        cursor: pointer;
        border-radius: 8px;
        img {
          width: 100%;
          height:100%;
          // height: 100%;
          // object-fit: cover;
        }
        &:last-child {
          margin-right: 0;
        }
        b {
          position: absolute;
          font-size: 26px;
          color: #609bf6;
          left: 10%;
          top: 50%;
          transform: translateY(-50%);
          // cursor: default;
        }
      }
    }
  }
  .recommend-course {
    & > a {
      font-size: 18px;
      float: right;
    }
    .recommend-box {
      padding: 24px 0;
      box-sizing: border-box;
      .recommend-item {
        display: inline-block;
        width: 284px;
        height: 170px;
        margin-right: 21px;
        border-radius: 5px;
        overflow: hidden;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
        .recommend-item-img {
          height: 172px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .recommend-item-text {
          color: #333333;
          font-size: 16px;
          padding: 0 4px;
          margin: 0;
          margin-top: 13px;
          overflow: hidden;
          &::after {
            content: "";
            display: block;
            clear: both;
          }
          .text-title {
            float: left;
          }
          .text-time {
            float: right;
          }
        }
      }
    }
  }
}


// 购买弹窗
// 支付弹窗
.mask-code {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index:999999999999;
  .code-box {
    width: 380px;
    height: 540px;
    border-radius: 4px;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
    background: #fff;
    border: 1px solid #e5e5e5;
    padding-top: 1px;
    .code-img {
      width: 250px;
      height: 250px;
      margin: 0 auto;
      margin-top: 20%;
    }
    p {
      font-size: 20px;
      color: #353535;
      text-align: center;
      margin-top: 50px;
    }
    .icon {
      font-size: 20px;
      float: right;
      margin: 15px;
      cursor: pointer;
    }
  }
}
// 100%
.courseListShow{
    height:650px;
    width: 730px;
    background:#ffff;
    border: 1px solid #707070;
    border-radius: 8px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0px 0px;
    box-sizing: border-box;
    z-index:9999;
    padding-top:100px;
    .icon-close{
      width:30px;
      height:30px;
      position:absolute;
      top:20px;
      right:10px;
      z-index:999999;
    }
    .showTop{
      width:100%;
      height:125px;
      position:absolute;
      left:0;
      top:0;

    }
    .showItemBox{
      width:100%;
      height:495px;
      overflow-y:scroll;
      // margin-top:20px;
      margin-top:5px;
      margin-bottom:45px;
       .showItem{
         margin-left:20px;
         margin-right:20px;
         border-radius:10px;
          background:#fff;
          padding:0px 25px;
          margin-top:9px;
          padding-bottom:20px;

    }
    }

    .showBottom{
       width:100%;
       height:50px;
       display:flex;
       justify-content:space-between;
       background:#f1f1f1;
       position:absolute;
       bottom:0;
       left:0;
       border-radius:5px;
       .bottomLeft{
          font-size:20px;
          margin-left:20px;
          margin-top:10px;

       }
       .bottomRight{
          font-size:16px;
          height:70%;
          line-height:70%;
          margin-top:8px;
          margin-right:20px;
          padding-top:10px;
       }

    }
}

.mask {
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.39);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1111;
  .register {
    max-width: 680px;
    max-height: 647px;
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 1% 8%;
    box-sizing: border-box;
    span {
      display: inline-block;
      margin-top: 6%;
      color: #1890ff;
      font-size: 29px;
      position: relative;
      &::after {
        content: "";
        display: block;
        width: 46px;
        height: 3px;
        background: #1890ff;
        position: absolute;
        left: 50%;
        bottom: -5px;
        transform: translateX(-50%);
      }
    }
    i {
      position: absolute;
      right: 3%;
      font-size: 24px;
      cursor: pointer;
    }
    form {
      width: 350px;
      max-height: 500px;
      margin: 10% auto;
      label {
        display: block;
        width: 350px;
        height: 40px;
        background: #ffffff;
        border: 1px solid rgba(217, 217, 217, 0.65);
        border-radius: 20px;
        padding: 6px 5px;
        box-sizing: border-box;
        margin-bottom: 24px;
        background: url("../assets/images/sprite.png") no-repeat;
        position: relative;
        &::before {
          content: "*";
          color: red;
          position: absolute;
          top: 50%;
          left: -6%;
          transform: translateY(-50%);
        }
        input {
          width: 80%;
          height: 75%;
          border: 0;
          outline: 0;
          font-size: 16px;
          &::placeholder {
            color: rgba($color: #000000, $alpha: 0.3);
          }
        }

        b {
          font-weight: normal;
          color: #f56c6c;
          font-size: 12px;
          position: absolute;
          left: 0;
          bottom: -50%;
          opacity: 0;
          transition: all 0.5s;
        }
      }
      button.now-register {
        width: 239px;
        height: 40px;
        background: #8bb9fc;
        border-radius: 20px;
        font-size: 15px;
        color: #ffffff;
        border: 0;
        margin-top: 12px;
        cursor: pointer;
      }
      .label-name-icon {
        background-position: 4% -3%;
      }
      .label-unit-icon {
        background-position: 4% 18%;
      }
      .label-phone-icon {
        background-position: 4% 39%;
      }
      .label-code-icon {
        position: relative;
        background-position: 4% 61%;
        button.send-code-btn {
          height: 100%;
          border: 0;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          font-size: 12px;
          padding: 10px;
          box-sizing: border-box;
          border-radius: 50px;
          color: #000;
          cursor: pointer;
        }
        .send-disable {
          cursor: not-allowed !important;

          background-color: #e7e8e9;
        }
      }
      .label-password-icon {
        background-position: 4% 82%;
      }
    }
  }
  .login-mask {
    width: 470px;
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 20px 50px;
    box-sizing: border-box;
    span {
      display: inline-block;
      margin-top: 6%;
      color: #1890ff;
      font-size: 29px;
      position: relative;
      margin-bottom: 47px;
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        background: #1890ff;
        position: absolute;
        left: 50%;
        bottom: -5px;
        transform: translateX(-50%);
      }
    }
    i {
      position: absolute;
      right: 3%;
      font-size: 24px;
      cursor: pointer;
    }
  }
  .forget-mask {
    width: 500px;
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 20px 50px;
    box-sizing: border-box;
    span {
      display: inline-block;
      margin-top: 6%;
      color: #1890ff;
      font-size: 29px;
      position: relative;
      margin-bottom: 47px;
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        background: #1890ff;
        position: absolute;
        left: 50%;
        bottom: -5px;
        transform: translateX(-50%);
      }
    }
    i {
      position: absolute;
      right: 3%;
      font-size: 24px;
      cursor: pointer;
    }
    .msg-box {
      display: flex;
      button {
        width: 40%;
        border: 0;
        background-color: rgba($color: #8bb9fc, $alpha: 0.8);
        border-radius: 50px;
        cursor: pointer;
        color: #fff;

        &:hover {
          background-color: rgba($color: #8bb9fc, $alpha: 0.6);
        }
      }
    }
  }
}

.tech-support {
  width:100%;
  text-align: center;
  font-size: 14px;
  color: #b0b0b0;
  background: #f1f1f2;
  height: 50px;
  line-height: 50px;
  position:absolute;
  bottom:0px;
}
  // 100%
  @media screen and (max-width: 1920px) and (min-width: 1540px){
.tech-support {
  width:100%;
  text-align: center;
  font-size: 14px;
  color: #b0b0b0;
  background: #f1f1f2;
  height: 50px;
  line-height: 50px;
  position:absolute;
  bottom:-78.5px;
}
  }
   //125%
  @media screen and (max-width: 1540px) and (min-width: 1280px){
    .tech-support {
  width:100%;
  text-align: center;
  font-size: 14px;
  color: #b0b0b0;
  background: #f1f1f2;
  height: 50px;
  line-height: 50px;
  position:absolute;
  // bottom:-238px; //125%设置
  bottom:-266px; //125%手动放大
}
    }
    //1366
     @media screen and (max-width: 1380px) and (min-width:1280px){
.tech-support {
  width:100%;
  text-align: center;
  font-size: 14px;
  color: #b0b0b0;
  background: #f1f1f2;
  height: 50px;
  line-height: 50px;
  position:absolute;
  bottom:-205px; //1366设置
}
     }
     //150%
  @media screen and (max-width: 1280px) and (min-width: 800px){
.tech-support {
  width:100%;
  text-align: center;
  font-size: 14px;
  color: #b0b0b0;
  background: #f1f1f2;
  height: 50px;
  line-height: 50px;
  position:absolute;
  // bottom:-204px; //150%设置
  bottom:-272px;  //150%手动设置
}
  }
</style>
<style lang="scss">
  .el-collapse-item__header{
  font-size: 16px;
  color: #000000;
}
.el-collapse-item__content{
  font-size: 12px;
  color: #000000;
  padding-bottom: 10px;
}
:root {
  --animate-duration: 0.3s;
}
.mask {
  .login-mask,
  .forget-mask {
    .el-input__inner {
      border-radius: 50px;
    }
    .el-button {
      border-radius: 50px;
      background-color: #8bb9fc;
      border: 0;
      color: #fff;
    }
  }
}
.register {
  .el-autocomplete {
    position: absolute;
    top: 13%;
    left: 10%;
    height: 110%;
    .el-input {
      height: 68%;
    }
    input {
      width: 120%;
      height: 100%;
      border: 0;
      outline: 0;
      font-size: 16px;
      padding: 0;
      padding-left: 5px;
      &::placeholder {
        color: rgba($color: #000000, $alpha: 0.3);
      }
    }
  }
}
</style>
<style>
::-webkit-scrollbar {
  /* 竖滚动条尺寸 */
  width: 8px;
  /* 横滚动条尺寸 */
  height: 12px;
  /* 滚动末端轨道背景颜色 */
  background-color: #f5f5f5;
}
/*定义滚动条轨道*/
::-webkit-scrollbar-track {
  /* 内阴影 */
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  /* 圆角 */
  border-radius: 10px;
  /* 背景颜色 */
  background-color: #f5f5f5;
}
/*定义滑块*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #bdbdbd;
}
/*鼠标悬停  滑块效果*/
::-webkit-scrollbar-thumb:hover {
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.4);
}
</style>
